<template>
    <div id="content" class="report report-productList" :class="[$mq, { menuOpen: !isExpanded }]">
        <div class="btn-actions">
            <div class="settings hide">
                <Button bType="settings" customClass="report" :bCallback="handleSettings"></Button>

                <div class="answers" v-if="settingsIsOpen">
                    <div class="option">
                        <input class="switch-input second" type="checkbox" v-model="showImg" :value="true" @click.stop />
                        <div class="label">{{ $t('nvgt.report.hide_img') }}</div>
                    </div>
                    <div class="option">
                        <input class="switch-input second" type="checkbox" v-model="showNotes" :value="true" @click.stop />
                        <div class="label">{{ $t('nvgt.report.hide_notes') }}</div>
                    </div>
                </div>
            </div>
            <div class="bar-actions hide">
                <Button bType="back" :bCallback="goBack"></Button>
                <Button bType="print" :bCallback="doPrint" bLabel="nvgt.report.print"></Button>
            </div>
        </div>
        <div class="report-main-container" :class="[$mq]">
            <div class="main-content fullscreen">
                <div class="report-container">
                    <div class="report toPrint" :class="[$mq]">
                        <div class="header">
                            <div class="version_code" id="version_code">{{ generatedDate() }}</div>
                            <div class="brands">
                                <div class="brand" v-if="account.avatar"><img :src="account.avatar" alt="" /></div>
                                <div class="brand text" v-else>{{ account.account_name }}</div>
                                <div class="brand alex">
                                    <img class="image" src="~@/../public/img/logo_andy.png" alt="Andy" />
                                </div>
                            </div>
                        </div>
                        <template v-if="filteredLocations.locations">
                            <div class="location" v-for="location in filteredLocations.locations" :key="location.id">
                                <span style="font-weight: bold">{{ getIdentification(location.id) }}</span> - {{ location.name }}
                            </div>
                        </template>
                        <template v-if="filteredLocations.groups">
                            <div class="location" v-for="group in filteredLocations.groups" :key="group.id">
                                {{ group.name }}
                            </div>
                        </template>

                        <div class="report-main-content" :class="{ fullwidth: Object.values(items).length == 1 }">
                            <h2 class="report-title">{{ $t('nvgt.report.title') }}</h2>
                            <template>
                                <div v-for="item in items" :key="item.id" :data="items[$route.params.id]" class="report-item">
                                    <div class="product-img" v-if="item.image !== 'false' && showImg" :style="{ backgroundImage: 'url(' + item.image + ')' }"></div>
                                    <div class="product-info">
                                        <div class="product-name">{{ item.name }}</div>
                                        <!-- <div class="product-category">{{ categories[item.category_id].name }}</div> -->
                                        <div class="product-note" v-if="item.note" v-html="item.note"></div>
                                        <div class="states">
                                            <div v-for="state in Object.values(item.states)" class="state-item" :key="state.id">
                                                <div class="state-col state-name">{{ states[state.state].name }}</div>
                                                <div class="state-col state-time">
                                                    <span v-if="state.msg" class="exp-msg">{{ state.msg }}</span>
                                                    <span v-if="state.time" class="exp-date">{{ formatMinutesToMax(state.time) }}</span>
                                                    <template v-if="state.preptime && state.preptime > 0">
                                                        <span class="prep-time"> {{ $t('nvgt.report.uso') }} </span><span class="prep-date">{{ formatMinutesToMax(state.preptime) }}</span>
                                                    </template>
                                                </div>
                                                <div class="state-col state-message" v-if="state.info && showNotes" v-html="(state.info.note1 ? state.info.note1 : '') + ' ' + (state.info.note2 ? state.info.note2 : '') + ' ' + (state.info.note3 ? state.info.note3 : '')"></div>
                                            </div>
                                        </div>
                                        <div class="product-availability">
                                            <Tags v-if="Object.values(item.locations).length > 0" :backImage="'location_inactive_s30.svg'" :tags="Object.values(item.locations)" :nameTag="'name'" value="id" :limit="false"></Tags>
                                            <Tags v-if="Object.values(item.groups).length > 0" :backImage="'menu_inactive_s30.svg'" :tags="Object.values(item.groups)" :nameTag="'name'" value="id" :limit="false"></Tags>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Report from '@/components/ui/Report'
import ShareURL from '@/components/ui/ShareURL'
import Tags from '@/components/ui/Tags'

export default {
    name: 'ExportProduct',
    props: {
        reports: { type: Array }
    },
    components: {
        Report,
        ShareURL,
        Tags
    },
    data() {
        return {
            count: 0,
            headers: [
                {
                    text: this.$t('supervise.productLists.table_headers.productLists'),
                    align: 'start',
                    sortable: false,
                    value: 'title',
                    width: '30%',
                    class: 'header-table'
                }
            ],
            showImg: true,
            showNotes: true,
            settingsIsOpen: false,
            showFullText: false
        }
    },
    computed: {
        account() {
            return this.$store.getters['login/getLocalAccount']
        },
        filteredLocations() {
            var result = this.$store.getters['label/getFiltersProductActive']
            if (result) {
                return result
            } else return false
        },
        items() {
            let result = [...this.$store.getters['label/getSortedLabels']]
            if (result && result.length > 0) {
                result.sort((a, b) => {
                    const nameA = a.name.toLowerCase()
                    const nameB = b.name.toLowerCase()

                    if (nameA < nameB) {
                        return -1
                    }
                    if (nameA > nameB) {
                        return 1
                    }
                    return 0
                })
                return result
            }
            return []
        },
        isExpanded() {
            return this.$store.state['menu_tools_is_expanded']
        },
        states() {
            console.warn(this.$store.getters['label/getFilterStates']())
            return this.$store.getters['label/getFilterStates']()
        },
        categories() {
            console.warn(this.$store.getters['label/getAllCategories']())
            return this.$store.getters['label/getAllCategories']()
        }
    },
    methods: {
        load() {
            var self = this
            self.$overlay.show()
            var items = { ...self.$store.getters['label/getSortedLabels'] }
            var filters = self.$store.getters['label/getFilterStatus']()
            var categories = self.$store.getters['label/getCategories']()

            // HEADERS OF MULTI-LANGUAGE
            var translationHeader = {
                text: this.$t('multi-language.translations'),
                value: 'translations',
                align: 'start',
                width: '300px',
                class: 'header-table'
            }
            var hasTranslation = self.headers.filter((header) => {
                return header.value == 'translations'
            })

            if (!this.organizationRender) {
                this.$store.dispatch('account/loadListOrganization', {}).then(function () {
                    if (self.organizationRender.availableLangs.length > 0 && hasTranslation == 0) {
                        self.headers.splice(4, 0, translationHeader)
                    }
                })
            } else {
                if (self.organizationRender && self.organizationRender.availableLangs.length > 0 && hasTranslation.length == 0) {
                    self.headers.splice(4, 0, translationHeader)
                }
            }

            if (Object.keys(items).length > 0 && Object.keys(categories).length > 0 && Object.keys(filters).length > 0) {
                if (self.$route.params.id) {
                    self.goToReport(self.$route.params.id)
                } else if (self.$route.name == 'ExportProduct') {
                    self.exportPDF = true
                } else {
                    self.form = false
                    self.productID = false
                }

                self.$overlay.hide()
            } else {
                self.$store.dispatch('label/loadFiltersProducts', {}).then(function () {
                    self.$store.dispatch('label/loadCategoriesForm', {}).then(function () {
                        if (self.$route.params.id) {
                            self.goToReport(self.$route.params.id)
                        } else {
                            self.form = false
                            self.productID = false
                        }
                        self.$overlay.hide()
                    })
                })
            }
        },
        handleSettings() {
            this.settingsIsOpen = !this.settingsIsOpen
        },
        leftTime(valDate) {
            var num = valDate
            var hours = num / 60
            var rhours = Math.floor(hours)
            var minutes = (hours - rhours) * 60
            var rminutes = Math.round(minutes)
            var result = ''
            if (rhours == 0) {
                result = '+' + rminutes + ' min'
            } else if (rhours > 1) {
                result = '+' + rhours + 'h y ' + rminutes + ' min'
                if (rminutes == 0) {
                    result = '+' + rhours + 'horas'
                }
            } else {
                result = '+' + rhours + 'h y ' + rminutes + ' min'
                if (rminutes == 0) {
                    result = '+' + rhours + 'h'
                }
            }
            return result
        },

        changeReport(id) {
            this.$router.push({ name: 'ExportProduct', params: { id: id } })
            this.$overlay.hide()
        },

        goBack() {
            this.$emit('back')
        },

        scoreColors(score) {
            if (score >= 100) {
                return 'done'
            } else if (score > 75) {
                return 'progress'
            } else if (score > 25) {
                return 'warning'
            } else {
                return 'alert'
            }
        },
        spanishDateFormat(valDate) {
            var localLocale = moment(valDate)
            // moment.locale('es')
            localLocale.locale(false)
            return localLocale.format('DD/MM/YY')
        },
        doPrint() {
            this.$store.dispatch('label/productPdfPrintlog', { id: this.$route.params.id })

            // report print scroll to bottom
            // this.$refs.reportPrint.$el.scrollTop = this.$refs.reportPrint.$el.scrollHeight
            setTimeout(() => {
                window.print()
            }, 10)
        },
        generatedDate() {
            var now = moment()
            return this.$t('nvgt.report.generated_date', { c1: now.format('DD/MM/YYYY'), c2: now.format('HH:mm') })
        },
        formatMinutesToMax(val, formatF) {
            val = parseFloat(val)
            var negative = false
            if (isNaN(val)) val = 0
            if (val < 0) {
                val = Math.abs(val)
                negative = true
            }
            var years, months, days, hours, minutes

            if (val >= 525600) {
                years = Math.floor(val / 525600)
                val = val % 525600
            }

            if (val >= 43200) {
                months = Math.floor(val / 43200)
                val = val % 43200
            }
            if (val >= 1440) {
                days = Math.floor(val / 1440)
                val = val % 1440
            }
            if (val >= 60) {
                hours = Math.floor(val / 60)
                val = val % 60
            }

            minutes = val
            var result = ''
            if (negative) {
                result = '-'
            }

            if (formatF == 'state') {
                result += (years > 0 ? years + this.$t('assets.timing.year_initials') : '') + ' ' + (months > 0 ? months + this.$t('assets.timing.month_initials') : '') + ' ' + (days > 0 ? days + this.$t('assets.timing.day_initials') : '') + ' ' + (hours > 0 ? hours + this.$t('assets.timing.hour_initials') : '') + ' ' + (minutes > 0 ? ' ' + minutes + "'" : '')
            } else {
                result += (years > 0 ? years + ' ' + this.$tc('assets.timing.year', years) : '') + ' ' + (months > 0 ? months + ' ' + this.$tc('assets.timing.month', months) : '') + ' ' + (days > 0 ? days + ' ' + this.$tc('assets.timing.day', days) : '') + ' ' + (hours > 0 ? hours + ' ' + this.$tc('assets.timing.hour', hours) : '') + ' ' + (minutes > 0 ? ' ' + minutes + ' ' + this.$tc('assets.timing.minute', minutes) : '')
            }
            if (negative) {
                result = result.replace('-  ', '-')
                result = result.replace('- ', '-')
            }

            return result
        },
        getIdentification(id) {
            var result = this.$store.getters['location/getList'](id)
            if (result) {
                return result.identification
            } else return false
        }
    },
    created() {
        this.load()
    },
    mounted() {
        // get localstorage variable showDetailReport
        this.showDetails = localStorage.getItem('showDetailReport') == 'true' ? true : false
    },
    watch: {
        showDetails(val) {
            localStorage.setItem('showDetailReport', val)
        }
    }
}
</script>

<style lang="scss" scoped>
$radius: 3px;
$ans-width: 100%;

#content.report-productList {
    padding: 0;
    > .report-main-container {
        margin: 0px 0px 30px 0px;
    }
}

.report-container {
    @extend .scrollbar;
    @extend .scrollbar-white;
    text-align: center;
    height: 100%;
    margin: 0px 0px 30px 0px;

    .report {
        @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-600, 1));
        padding: 27px;
        display: table;
        width: 21cm;
        min-height: 29.4cm;
        background-color: #fff;
        border-radius: 4px;
        height: fit-content;
        overflow: auto;
        overflow-x: hidden;
        .version_code {
            @include font-size('s');
            font-family: $text-medium;
            font-style: italic;
            width: 100%;
            text-align: center;
            margin-bottom: 5px;
            color: $color-neutral-600;
        }

        .brands {
            overflow: hidden;
            margin-bottom: 2%;

            .brand {
                height: 50px;
                display: inline-block;
                float: left;

                &.text {
                    @include display-flex();
                    @include flex-direction(row);
                    @include justify-content(flex-start);
                    @include align-items();
                    @include font-size('l');
                    @include text-ellipsis();
                    font-family: $text-bold;
                    padding: 5px;
                    max-width: calc(100% - 140px);
                    height: 50px;
                }

                &.alex {
                    text-align: right;
                    float: right;

                    .image {
                        height: 100%;
                        width: auto;
                        display: inline;
                        margin: 0;
                    }
                }

                img {
                    height: 100%;
                    width: auto;
                }
            }
        }

        .location {
            margin-top: 4px;
        }

        .report-main-content {
            margin: 24px 0 24px 0;
            width: 100%;

            .report-title {
                height: 21.5px;
                padding: 10px 10px 35px 0px;
                color: #2d2e2f;
                font-weight: 500;
                font-size: 20px;
                line-height: 23px;
                letter-spacing: 0px;
                text-align: left;
                border-bottom: 2px solid #eae6ee;
            }

            .report-item {
                border-bottom: 1px solid #eae6ee;
                display: flex;
                page-break-inside: avoid;
                padding: 8px 0;

                .product-img {
                    @include background($size: contain);
                    width: 35px;
                    height: 35px;
                    border-radius: 4px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    margin: 8px;
                }

                .product-info {
                    width: 100%;
                    padding-left: 8px;

                    .states {
                        .state-item {
                            display: grid;
                            grid-template-columns: calc(25% - 4px) calc(25% - 4px) calc(50% - 4px);
                            gap: 8px;
                            padding: 2px 0;

                            .state-col {
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                @include font-size(xs);
                                overflow: hidden;
                                display: -webkit-box;

                                ::v-deep b {
                                    @include font-size(xs);
                                }
                            }

                            .state-name {
                                @include border-radius(2px);
                                font-family: $text;
                            }

                            .state-time {
                                text-align: left;
                                word-break: break-word;

                                .exp-date,
                                .exp-msg {
                                    flex: 1;
                                    font-weight: bold;
                                    @include font-size(xs);
                                }
                            }

                            .state-message {
                                flex: 1;
                            }
                        }
                    }
                    .product-name {
                        font-weight: bold;
                        @include font-size(md);
                        font-family: $text-bold;
                        margin-bottom: 4px;
                    }
                    .product-category {
                        font-weight: bold;
                        @include font-size(md);
                        font-family: $text-bold;
                        margin-bottom: 4px;
                    }

                    .product-note {
                        @include font-size(xs);
                        margin-bottom: 16px;

                        ::v-deep * {
                            @include font-size(xs);
                        }
                    }
                }

                .prep-time,
                .prep-date,
                .state-message {
                    font-size: 11px;
                }
            }
        }
    }
}
</style>
