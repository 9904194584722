<template>
    <div class="main-state">
        <div v-if="assignedState && assignedState.status != -1" class="product-assign-state">
            <div class="state-box" mode="out-in">
                <div v-if="states.error || states.error1" class="error-text">
                    <p v-if="states.error1" class="error-form error-field" v-html="$t('product.error_state')"></p>
                    <p v-if="states.error" class="error-form error-field" v-html="$t('product.time_empty')"></p>
                </div>
                <div class="content-state translate-disable">
                    <div class="container-state">
                        <div class="label">
                            <div class="field-title">
                                {{ $t('product.label_design') }}
                            </div>
                            <div class="label-design">
                                <v-autocomplete hide-details class="white" v-model="assignedState.config_id" :items="Object.values(optionsLabels)" item-text="name" item-value="id" dense :no-data-text="$t('supervise.filter.no_results')" @change="updateProductState()"></v-autocomplete>
                                <div class="preview" :items="items" @click="statePreview"></div>
                            </div>
                        </div>
                        <div class="state">
                            <div class="field-title">
                                {{ $t('product.state_Capital') }}
                            </div>
                            <v-autocomplete hide-details class="white" v-model="assignedState.state" :items="Object.values(optionsState)" item-text="name" item-value="id" dense :no-data-text="$t('supervise.filter.no_results')" @change="updateProductState()"></v-autocomplete>
                        </div>
                        <div class="format">
                            <div class="time" v-tooltip="$t('product.time_calc')" @click="stateFormat = 'time'" :class="{ active: stateFormat == 'time' }"></div>
                            <div class="annotation" v-tooltip="$t('product.annotation')" @click="stateFormat = 'annotation'" :class="{ active: stateFormat == 'annotation' }"></div>
                        </div>
                        <div class="format-settings">
                            <transition name="fade" mode="out-in">
                                <div class="time-container" key="1" v-if="stateFormat == 'time'">
                                    <div class="time">
                                        <div class="field-title">
                                            {{ $t('product.expired_time') }}
                                        </div>
                                        <div class="time-inputs">
                                            <input :class="{ error: states.error }" type="number" min="0" class="input" v-model="timeValue" @keyup="updateProductState()" @mouseup="updateProductState()" />
                                            <v-select hide-details class="white" v-model="timeFormat" :items="optionsTimeFormat" item-text="name" item-value="value" dense :no-data-text="$t('supervise.filter.no_results')" @change="updateProductState()"></v-select>
                                        </div>
                                    </div>
                                    <div class="preptime" v-tooltip="$t('product.time_desc')" @click="hasPrepTime = true" v-if="!hasPrepTime" @change="updateProductState()"></div>
                                    <div class="time-defrosting" v-else>
                                        <div class="field-title">
                                            {{ $t('product.defrosting') }}
                                        </div>
                                        <div class="time-inputs">
                                            <input :class="{ error: states.error }" type="number" min="0" class="input" v-model="timePrepValue" @keyup="updateProductState()" @mouseup="updateProductState()" />
                                            <v-select hide-details class="white" v-model="timePrepFormat" :items="optionsTimeFormat" item-text="name" item-value="value" dense :no-data-text="$t('supervise.filter.no_results')" @change="updateProductState()"></v-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="annotation" v-else>
                                    <div key="2" class="annotation-description">
                                        {{ $t('product.expired_time') }}
                                    </div>

                                    <TranslateInput :translation_keyname="'product.' + $route.params.id + '.state.' + assignedState.id + '.msg'" v-if="stateFormat == 'annotation'" v-model="assignedState.msg" @input="updateProductState()"></TranslateInput>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div class="second-row">
                        <div class="adjust">
                            <div class="showToggle">
                                <span class="checkToggle" :class="{ checked: showStatus('lote') }"></span>
                                {{ $t('product.lote') }}
                            </div>

                            <div class="showToggle">
                                <span class="checkToggle" :class="{ checked: showStatus('caducidad') }"></span>
                                {{ $t('product.primary_expiration') }}
                            </div>
                        </div>
                        <transition name="fade" mode="in-out">
                            <!-- <div class="info translate-exception" v-if="extra_info_button && assignedState.info != null"> -->
                            <div class="info translate-exception" v-if="showStatus('note1') || showStatus('note2') || showStatus('note3')">
                                <div class="field-title">
                                    {{ $t('product.customized') }}
                                </div>
                                <div class="extra-notes-inputs">
                                    <TranslateInput v-if="showStatus('note1')" :translation_keyname="'product.' + $route.params.id + '.state.' + assignedState.id + '.note1'" v-model="assignedState.info.note1" :_placeholder="$t('product.form.note1')" @input="updateProductState()"></TranslateInput>
                                    <TranslateInput v-if="showStatus('note2')" :translation_keyname="'product.' + $route.params.id + '.state.' + assignedState.id + '.note2'" v-model="assignedState.info.note2" :_placeholder="$t('product.form.note2')" @input="updateProductState()"></TranslateInput>
                                    <TranslateInput v-if="showStatus('note3')" :translation_keyname="'product.' + $route.params.id + '.state.' + assignedState.id + '.note3'" v-model="assignedState.info.note3" :_placeholder="$t('product.form.note3')" @input="updateProductState()"></TranslateInput>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="sel-state" v-if="addNextState || next_state">
                    <div class="text">{{ $t('product.form.next_state') }}</div>
                    <v-select hide-details v-model="next_state" :items="next_states" item-text="name" item-value="id" dense @change="updateProductState()" :no-data-text="$t('supervise.filter.no_results')"></v-select>
                </div>
            </div>

            <div>
                <div class="extra-info translate-hide">
                    <div class="next-state-button" :class="{ 'hidden-button': !hasNextState, active: addNextState || next_state }" v-tooltip="$t('product.form.add_next_state')" @click="openNextState()"></div>
                </div>

                <div @click="deleteState()" class="delete translate-hide"></div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'ProductAssignedState',
    props: {
        productRender: Object,
        states: undefined,
        next_states: Array,
        empty: { type: undefined, default: false }
    },
    components: {},
    data() {
        return {
            statesArray: [],
            timeValue: '',
            timePrepFormat: '',
            timePrepValue: '',
            timeFormat: '',
            state: '',
            addNextState: false,
            updateFormat: true,
            stateFormat: 'annotation',
            next_state: false,
            extra_info_button: false,
            hasPrepTime: false,
            oParams: {},
            processData: {},
            newPreviewData: {}
        }
    },
    computed: {
        items() {
            this.recepcion = false
            const self = this
            let labels = Object.values(this.$store.getters['label/getListTraceability']).length >= 1 ? this.$store.getters['label/getListTraceability'] : []
            labels.forEach(function (label) {
                label.batch = ''
                label.primary = false

                if (label.elaborate != '') {
                    label.primary = label.elaborate
                    label.recepcion = ''
                } else {
                    let match = label.data.match(/"lote":"([^"]*)"/)
                    if (match && match.length > 1) {
                        label.batch = match[1]
                    }

                    match = label.data.match(/"caducidad":"([^"]*)"/)
                    if (match && match.length > 1) {
                        label.primary = match[1].replaceAll('\\', '')
                        const isNumeric = /^-?\d+$/.test(label.primary)

                        if (isNumeric) {
                            const date = new Date(label.primary * 1000)
                            const day = String(date.getDate()).padStart(2, '0')
                            const month = String(date.getMonth() + 1).padStart(2, '0')
                            const year = String(date.getFullYear())
                            const formattedDate = `${day}/${month}/${year}`
                            label.primary = `${formattedDate}`
                        } else {
                            label.primary = label.primary.split(' ')[0]
                        }
                    }

                    // recepción
                    if (parseInt(label.recepcion) === 1) {
                        self.recepcion = true
                        label.recepcion = label.primary
                        label.primary = ''
                    } else {
                        label.recepcion = ''
                    }
                }
            })
            return labels
        },
        assignedState() {
            if (this.states) {
                var state = { ...this.states }
                if (state.new) {
                    this.stateFormat = 'time'
                    this.timeValue = 0
                    this.timeFormat = 'day'
                    this.timePrepValue = 0
                    this.timePrepFormat = 'day'
                    state.new = false
                    state.info = {}
                } else {
                    if (!state.state) {
                        state.state = Object.values(this.optionsState)[0].id
                    }
                    if (!state.info) {
                        state.info = {}
                    }
                    this.extra_info_button = true
                    if (state.time != 0 && state.time != null) {
                        // this.stateFormat = this.stateFormat != 'annotation' || typeof state.format === 'undefined' ? 'time' : this.stateFormat
                        if (this.updateFormat) {
                            this.timeValue = this.formatTimeToMax(state.time).result
                            this.timeFormat = this.formatTimeToMax(state.time).format
                        }
                    } else {
                        this.timeValue = 0
                        // if (this.timeFormat != '' && this.stateFormat != 'annotation') this.stateFormat = 'time'
                        this.timeFormat = this.timeFormat == '' ? 'day' : this.timeFormat
                    }
                    if (state.preptime != 0 && state.preptime != null) {
                        // this.stateFormat = this.stateFormat != 'annotation' || typeof state.format === 'undefined' ? 'time' : this.stateFormat
                        if (this.updateFormat) {
                            this.timePrepValue = this.formatTimeToMax(state.preptime).result
                            this.timePrepFormat = this.formatTimeToMax(state.preptime).format
                            this.hasPrepTime = true
                        }
                    } else {
                        this.timePrepValue = 0
                        if (this.timePrepFormat != '' && this.stateFormat != 'annotation') this.stateFormat = 'time'
                        this.timePrepFormat = this.timePrepFormat == '' ? 'day' : this.timePrepFormat
                    }
                    if (state.next_state) {
                        this.addNextState = true
                    }
                }

                this.state = state.state
                this.next_state = state.next_state
                this.updateFormat = false

                return state
            }
            return false
        },
        hasNextState() {
            return this.$store.getters.getProductNextState
        },
        optionsTimeFormat() {
            return [
                { value: 'min', name: i18n.tc('assets.timing.minute', 2) },
                { value: 'hour', name: i18n.tc('assets.timing.hour', 2) },
                { value: 'day', name: i18n.tc('assets.timing.day', 2) },
                { value: 'month', name: i18n.tc('assets.timing.month', 2) },
                { value: 'year', name: i18n.tc('assets.timing.year', 2) }
            ]
        },

        optionsState() {
            return this.$store.getters['label/getStatesForm']()
        },
        optionsNextState() {
            var states = this.$store.getters['label/getStatesForm']()
            var aStates = []
            for (var k in states) {
                if (k != this.states.state) {
                    aStates.push(states[k])
                }
            }

            return aStates
        },
        optionsLabels() {
            var aux = Object.values(this.$store.getters['label/getFilterProductLabelConfig']())
            aux.sort(this.alphabetically(true))
            return aux
        },
        categories() {
            return this.$store.getters['label/getCategoriesForm']()
        }
    },
    methods: {
        openNextState() {
            if (this.addNextState) {
                this.addNextState = false
                this.next_state = false
            } else {
                this.addNextState = true
            }
            this.updateProductState()
        },
        openInfo() {
            if (!this.extra_info_button) {
                if (this.assignedState.info == null) {
                    this.assignedState.info = {
                        note1: '',
                        note2: '',
                        note3: ''
                    }
                }
                this.extra_info_button = true
            } else {
                this.extra_info_button = false
            }
        },
        alphabetically(ascending) {
            return function (a, b) {
                // equal items sort equally
                if (a.name === b.name) {
                    return 0
                }
                // nulls sort after anything else
                else if (a.name === null) {
                    return 1
                } else if (b.name === null) {
                    return -1
                }
                // otherwise, if we're ascending, lowest sorts first
                else if (ascending) {
                    return a.name < b.name ? -1 : 1
                }
                // if descending, highest sorts first
                else {
                    return a.name < b.name ? 1 : -1
                }
            }
        },
        showStatus(name) {
            let self = this
            let label = self.productRender

            if (label) {
                let format = self.$store.getters['label/getTemplates'](this.assignedState.config_id)
                if (format.data) {
                    let values = JSON.parse(format.data)
                    return Object.values(values).some((item) => item.includes(name))
                }
            }

            return false
        },
        statePreview() {
            var self = this
            let label = self.productRender
            let note = label.note ? label.note : ''
            self.oParams['note'] = note
            let format = self.$store.getters['label/getTemplates'](this.assignedState.config_id)
            let values = JSON.parse(format.data)

            if (format.realistic_preview) {
                let product = this.productRender
                const selectedState = Object.values(this.optionsLabels).find((item) => item.id === this.assignedState.config_id)
                self.selectedState = selectedState.name
                let params = {
                    title: selectedState.name,
                    date: self.assignedState.msg.trim() != '' ? self.assignedState.msg : moment().add(self.assignedState.time, 'minutes').format('DD/MM/YYYY HH:mm'),
                    product: product.name,
                    quantity: '1',
                    print_date: moment().format('DD/MM/YYYY HH:mm'),
                    prep_date: moment().add(self.assignedState.preptime, 'minutes').format('DD/MM/YYYY HH:mm'),
                    // date_raw: moment().format('DD/MM/YYYY HH:mm'),
                    note: product.note ? product.note : '',
                    note1: self.assignedState.info && self.assignedState.info.note1 ? self.assignedState.info.note1 : '',
                    note2: self.assignedState.info && self.assignedState.info.note2 ? self.assignedState.info.note2 : '',
                    note3: self.assignedState.info && self.assignedState.info.note3 ? self.assignedState.info.note3 : '',
                    state: self.optionsState[self.assignedState.state] ? self.optionsState[self.assignedState.state].name : ''
                }

                self.preview = JSON.parse(format.realistic_preview)

                let organization = this.$store.getters['account/getOrganization']
                let oLocation = Object.values(this.$store.getters['location/getList']())[0]

                params['account'] = organization.account.name
                params['location'] = oLocation.name
                params['city'] = oLocation.city
                params['address'] = oLocation.address
                params['loc_state'] = oLocation.state
                params['cp'] = oLocation.cp

                for (let key in self.preview.fields) {
                    let field = self.preview.fields[key]

                    let value = typeof field.f_id !== 'undefined' ? values[field.f_id] : field.value

                    const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                    let m
                    let aux_value = value

                    while ((m = regex.exec(value)) !== null) {
                        // This is necessary to avoid infinite loops with zero-width matches
                        if (m.index === regex.lastIndex) {
                            regex.lastIndex++
                        }

                        // The result can be accessed through the `m`variable.
                        m.forEach((match, groupIndex) => {
                            let m_index = match.replace(/[\{\}\[\]]/gi, '')
                            let name = this.$t('label.preview.' + m_index)
                            if (name.indexOf('label.preview.') != -1) {
                                name = m_index
                            }

                            if (!Object.keys(params).includes(m_index)) {
                                const regex2 = /(_d[0-9]|_day|_timestamp|_time)/gi
                                let n
                                while ((n = regex2.exec(m_index)) !== null) {
                                    // This is necessary to avoid infinite loops with zero-width matches
                                    if (n.m_index === regex2.lastIndex) {
                                        regex2.lastIndex++
                                    }

                                    // The result can be accessed through the `m`-letiable.
                                    n.forEach((match2, groupIndex) => {
                                        let keyValue = m_index.replace(match2, '')
                                        if (Object.keys(params).includes(keyValue)) {
                                            let d = moment(params[keyValue], 'DD/MM/YYYY HH:mm')

                                            if (!d.isValid()) {
                                                d = moment(parseInt(params[keyValue]) * 1000)
                                            }
                                            if (!d.isValid()) {
                                                params[m_index] = '-'
                                            } else {
                                                params[keyValue + '_day'] = d.format('DD/MM/YYYY')
                                                params[keyValue + '_time'] = d.format('HH:mm')
                                                params[keyValue + '_timestamp'] = parseInt(d.valueOf() / 1000).toString()
                                                params[keyValue + '_d1'] = d.isoWeekday() == 1 ? 'x' : ' '
                                                params[keyValue + '_d2'] = d.isoWeekday() == 2 ? 'x' : ' '
                                                params[keyValue + '_d3'] = d.isoWeekday() == 3 ? 'x' : ' '
                                                params[keyValue + '_d4'] = d.isoWeekday() == 4 ? 'x' : ' '
                                                params[keyValue + '_d5'] = d.isoWeekday() == 5 ? 'x' : ' '
                                                params[keyValue + '_d6'] = d.isoWeekday() == 6 ? 'x' : ' '
                                                params[keyValue + '_d7'] = d.isoWeekday() == 7 ? 'x' : ' '
                                            }
                                        } else if (Object.keys(params).includes(keyValue + '_timestamp') && params[keyValue + '_timestamp'] !== '-') {
                                            let d = moment(parseInt(params[keyValue + '_timestamp']) * 1000)
                                            params[keyValue + '_day'] = d.format('DD/MM/YYYY')
                                            params[keyValue + '_time'] = d.format('HH:mm')
                                            params[keyValue] = d.format('DD/MM/YYYY HH:mm')
                                            params[keyValue + '_d1'] = d.isoWeekday() == 1 ? 'x' : ' '
                                            params[keyValue + '_d2'] = d.isoWeekday() == 2 ? 'x' : ' '
                                            params[keyValue + '_d3'] = d.isoWeekday() == 3 ? 'x' : ' '
                                            params[keyValue + '_d4'] = d.isoWeekday() == 4 ? 'x' : ' '
                                            params[keyValue + '_d5'] = d.isoWeekday() == 5 ? 'x' : ' '
                                            params[keyValue + '_d6'] = d.isoWeekday() == 6 ? 'x' : ' '
                                            params[keyValue + '_d7'] = d.isoWeekday() == 7 ? 'x' : ' '
                                        } else {
                                            params[m_index] = '-'
                                        }
                                    })
                                }

                                if (!Object.keys(params).includes(m_index)) {
                                    params[m_index] = '-'
                                }
                            }

                            if (m_index != '' && Object.keys(params).includes(m_index)) {
                                aux_value = aux_value.replace(match, params[m_index])
                            }
                        })
                    }

                    self.preview.fields[key].value = aux_value
                    self.newPreviewData = Object.assign({}, self.preview)

                    this.$snackbar.close()

                    this.print_date = params.print_date
                }

                self.$popup.labelPreview({
                    data: self.newPreviewData,
                    title: self.selectedState
                })
            }
        },
        getState(id) {
            if (id) {
                var result = this.$store.getters['label/getFilterStates'](id)
                if (result) {
                    return result
                }
            } else {
                return false
            }
        },
        formatTimeToMax(timeToFormat) {
            // console.log(timeToFormat)
            if (typeof timeToFormat === 'undefined') return { result: null, format: 'min' }
            var year = 365 * 24 * 60
            var month = 30 * 60 * 24
            var day = 60 * 24
            var hours = 60
            var result = false
            var format = false

            if (timeToFormat % year == 0) {
                result = Math.floor(timeToFormat / year)
                format = 'year'
            } else if (timeToFormat < year && timeToFormat % month == 0) {
                result = Math.floor(timeToFormat / month)
                format = 'month'
            } else if (timeToFormat > year) {
                // Se calculan los años enteros y se obtienen los meses restantes, si los meses son enteros, los devuelve
                var years = Math.floor(timeToFormat / year)
                var months = timeToFormat - years * year
                if (months % month == 0) {
                    result = Math.floor(months / month) + years * 12
                    format = 'month'
                }
            } else if (timeToFormat % day == 0) {
                result = Math.floor(timeToFormat / day)
                format = 'day'
            } else if (timeToFormat % hours == 0) {
                result = Math.floor(timeToFormat / hours)
                format = 'hour'
            } else {
                result = timeToFormat
                format = 'min'
            }
            return { result: result, format: format }
        },

        convertToMinutes(time, format) {
            time = typeof time === 'undefined' ? 0 : time
            switch (format) {
                case 'year':
                    return time * 365 * 24 * 60
                case 'month':
                    var signal = time > 0 ? 1 : -1
                    var years = Math.floor(Math.abs(time) / 12)
                    // console.log('year', years)
                    var month = Math.abs(time) - years * 12
                    // console.log('month', month)
                    return signal * (years * 365 * 24 * 60 + month * 30 * 24 * 60)
                case 'day':
                    return time * 24 * 60
                case 'hour':
                    return time * 60
            }
            return time
        },

        changeMode() {
            if (this.stateFormat == 'annotation') {
                this.timePrepValue = 0
                this.timeValue = 0
                this.timeFormat = ''
                this.timePrepFormat = ''
            } else {
                this.assignedState.msg = ''
                this.stateFormat = 'time'
            }

            this.updateProductState()
            return true
        },

        deleteState() {
            this.assignedState.status = -1
            this.updateProductState()
        },

        updateProductState() {
            let self = this

            let stateAssigned = {
                state: self.assignedState.state,
                next_state: self.next_state,
                time: self.convertToMinutes(self.timeValue, self.timeFormat),
                preptime: self.convertToMinutes(self.timePrepValue, self.timePrepFormat),
                format: self.stateFormat,
                msg: self.assignedState.msg,
                status: self.assignedState.status,
                config_id: self.assignedState.config_id,
                new: self.assignedState.new,
                info: self.assignedState.info
            }
            if (self.assignedState.id) {
                stateAssigned.id = self.assignedState.id
            } else stateAssigned.idtemp = self.assignedState.idtemp
            self.$emit('updateState', stateAssigned, this.assignedState.config_id)
        }
    },
    created() {
        if (typeof this.states.new == 'undefined' && (this.states.preptime > 0 || this.states.time > 0)) {
            this.stateFormat = 'time'
        }
    },
    watch: {
        stateFormat: function (val) {
            this.changeMode()
        }
    },
    mounted() {},
    updated() {}
}
</script>

<style lang="scss">
.hidden-button {
    display: none;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.main-state {
    transition: all 0.3s;
}

.product-assign-state {
    height: 100%;
    width: 100%;
    @include display-flex();

    .state-box {
        @include border-radius(4px);
        @include background($color: $color-neutral-200);
        @include display-flex();
        @include justify-content(inherit);
        @include flex-direction(column);
        margin-bottom: 5px;
        width: 100%;
        border-left: 5px solid $color-primary-500;

        .error-text {
            @include align-self(center);
            width: 100%;
            text-align: -webkit-center;
            margin-top: 12px;
            margin-bottom: 6px;

            .error-form {
                width: fit-content;
            }
        }

        .content-state {
            .container-state {
                @include align-items(center);
                width: 100%;
                display: grid;
                grid-template-columns: 23% 23% 9% 23% 22%;

                .label,
                .state {
                    padding: 16px 20px 16px 20px;
                }

                .label-design {
                    display: flex;
                }
                .preview {
                    @include border-radius(3px);
                    @include background($image: img('eye_aaaaaa.svg'), $color: $color-white, $size: 20px);
                    width: 30px;
                    height: 30px;
                    padding: 6px;
                    margin-left: 8px;
                    cursor: pointer;

                    &:not(.active):hover {
                        transition: 0.3s;
                        @include bgHover($color-primary-100);
                        @include background($image: img('eye_primary.svg'), $color: $color-primary-500, $size: 20px);
                    }

                    &.active {
                        @include background($image: img('eye_light.svg'), $color: $color-primary-500, $size: 20px);
                    }
                }
                .format {
                    @include display-flex();
                    padding: 30px 20px 8px 20px;

                    .time {
                        @include background($image: img('time_inactive.svg'), $color: $color-white, $size: 20px);
                        width: 30px;
                        min-width: 25px;
                        height: 30px;
                        padding: 6px;
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                        cursor: pointer;

                        &:not(.active):hover {
                            transition: 0.3s;
                            @include bgHover($color-primary-100);
                            @include background($image: img('time_active.svg'), $color: $color-primary-500, $size: 20px);
                        }

                        &.active {
                            @include background($image: img('time_active.svg'), $color: $color-primary-500, $size: 20px);
                        }
                    }
                    .annotation {
                        @include background($image: img('annotation_dark.svg'), $color: $color-white, $size: 20px);
                        cursor: pointer;
                        width: 30px;
                        min-width: 25px;
                        height: 30px;
                        padding: 6px;
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;

                        &:not(.active):hover {
                            transition: 0.3s;
                            @include bgHover($color-primary-100);
                            @include background($image: img('annotation_light.svg'), $color: $color-primary-500, $size: 20px);
                        }

                        &.active {
                            @include background($image: img('annotation_light.svg'), $color: $color-primary-500, $size: 20px);
                        }
                    }
                }

                .format-settings {
                    .time-container {
                        @include display-flex();

                        .time {
                            padding: 16px 20px 16px 20px;
                            .time-inputs {
                                @include display-flex();

                                input {
                                    margin-right: 8px;
                                    width: 65px;
                                }
                            }
                        }

                        .time-defrosting {
                            padding: 16px 20px 16px 20px;
                            min-width: fit-content;
                            .time-inputs {
                                @include display-flex();

                                input {
                                    margin-right: 8px;
                                    width: 65px;
                                }

                                .v-input.white {
                                    max-width: min-content;
                                }
                            }
                        }

                        .preptime {
                            @include border-radius(3px);
                            @include background($image: img('more_assigned_state.svg'), $color: $color-white, $size: 20px);
                            width: 30px;
                            min-width: 30px;
                            height: 30px;
                            cursor: pointer;
                            margin: 39px 20px 16px -8px;
                        }
                    }
                }

                .v-input.white {
                    width: 100%;
                    max-height: 32px;
                    border-radius: 3px;
                    border: 1px solid #e2e2e2 !important;
                    &:hover {
                        border: 1px solid $color-black;
                    }
                }
            }
            .second-row {
                display: grid;
                grid-template-columns: 23% 77%;

                .adjust {
                    padding: 0px 20px 10px 20px;
                    .showToggle {
                        @include font-size(xs);
                        font-family: $text-light;

                        .checkToggle {
                            @include background($image: img('error_dddddd.svg'), $size: 20px);
                            width: 30px;
                            height: 30px;
                            padding: 10px;
                            margin-right: 5px;
                            cursor: default;
                            &.checked {
                                @include background($image: img('checked_second.svg'), $size: 20px);
                            }
                        }
                    }
                }
            }

            .info.translate-exception {
                width: 100%;
                padding: 0px 20px 10px 20px;

                transition: opacity 0.5s;

                .extra-notes-inputs {
                    @include display-flex();
                    width: 100%;
                    gap: 10px;
                }
            }
        }

        .sel-state {
            width: 23%;
            padding: 0px 20px 10px 20px;
        }
    }

    .v-select__selections input,
    .v-select__slot input,
    .v-select__selection--comma {
        @include font-size(sm);
        font-family: $text;
    }
    .v-select__selections {
        padding-left: 0px;
    }
    .v-select__selections input {
        @include background($color: $color-white !important);

        height: 20px;
        visibility: hidden;
    }
}

div.delete {
    @include background($image: img('delete_light.svg'), $size: 18px);
    background-color: $color-error-500;
    width: 30px;
    height: 30px;
    margin: 10px 0 0 8px;
    cursor: pointer;
    border-radius: 3px;
}
.extra-info {
    @include display-flex();
    width: 100%;

    .extra-notes {
        @include border-radius(3px);
        @include background($image: img('extra_notes.svg'), $color: $color-white, $size: 20px);
        width: 30px;
        height: 30px;
        padding: 6px;
        cursor: pointer;

        &:not(.active):hover {
            transition: 0.3s;
            @include bgHover($color-primary-100);
            @include background($image: img('extra_notes_active.svg'), $color: $color-primary-500, $size: 20px);
        }

        &.active {
            @include background($image: img('extra_notes_active.svg'), $color: $color-primary-500, $size: 20px);
        }
    }
    .next-state-button {
        @include border-radius(3px);
        @include background($image: img('next_state_button_black.svg'), $color: $color-neutral-200, $size: 20px);
        width: 30px;
        height: 30px;
        padding: 6px;
        margin-left: 8px;
        cursor: pointer;

        &:not(.active):hover {
            transition: 0.3s;
            @include bgHover($color-primary-100);
            @include background($image: img('next_state_button_white.svg'), $color: $color-primary-500, $size: 20px);
        }

        &.active {
            @include background($image: img('next_state_button_white.svg'), $color: $color-primary-500, $size: 20px);
        }
    }
}
</style>
