<template>
    <div id="content" class="product-management">
        <div class="bar-actions">
            <TranslateButton :entityId="'product.' + productRender.id" :translationLangs="productRender.translations" @newTranslation="newTranslation" @deleteTranslation="deleteTranslation"></TranslateButton>
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveProduct" customClass="btn-action" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="duplicate" :bCallback="clone" customClass="btn-duplicate" bLabel="menu_aux.action.clone"></Button>
                <Button bType="delete" :bCallback="showConfirmDeleteProductModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container" v-if="productRender">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>

                <div class="label-container">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('user.user_name') }}</h2>
                        <p v-if="emptyAnswer.name" class="error-form error-field" v-html="$t('library.form.error_name')"></p>
                        <div class="user-answer">
                            <TranslateInput :translation_keyname="'product.' + productRender.id + '.name'" v-model="productRender.name" :_class="'input w85'" :_placeholder="$t('product.name_placeholder')"></TranslateInput>
                        </div>
                        <div class="user-answer">
                            <!-- Old->
                        <input type="text" v-model="productRender.name" class="input w85" autocomplete="off" name="name" :placeholder="$t('product.name_placeholder')" /> -->
                        </div>
                    </div>
                    <div class="right-container translate-hide">
                        <div class="switch">
                            <h2 class="label-form" v-html="statusProduct ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                            <div class="switch-click" @click="popupStatus()">
                                <input class="switch-input-form" type="checkbox" v-model="statusProduct" disabled="true" />
                            </div>
                        </div>
                        <p class="description" v-html="$t('product.switch_desc')"></p>
                    </div>
                </div>
                <div class="label-container translate-hide">
                    <div class="left-container align-bottom">
                        <h2 class="label-form">{{ $t('product.category') }}</h2>
                        <!-- <p class="description" v-html="$t('product.product_category.priority_desc')"></p> -->
                        <p v-if="emptyAnswer.category" class="error-form error-field" v-html="$t('product.category_error')"></p>
                        <div class="user-answer select">
                            <v-autocomplete hide-details class="select" v-model="category_id" item-text="name" item-value="id" :items="Object.values(optionsCategories)" :placeholder="$t('product.category_placeholder')" dense :no-data-text="$t('supervise.filter.no_results')"></v-autocomplete>
                        </div>
                    </div>
                    <div class="right-container">
                        <!-- <h2 class="label-form">{{ $t('product.note') }}</h2>
                    <p class="description" v-html="$t('product.note_desc')"></p>
                    <p v-if="emptyAnswer.note" class="error-form error-field" v-html="$t('product.product_category.order_error')"></p>
                    <div class="user-answer">
                        <input type="text" v-model="productRender.note" class="input w85" autocomplete="off" name="name" />
                    </div> -->
                        <!-- NUEVO DESARROLLO -->
                        <h2 class="label-form">{{ $t('product.image') }}</h2>
                        <p class="description" v-html="$t('product.image_desc')"></p>
                        <div class="user-answer">
                            <div
                                v-if="icon"
                                class="image"
                                :style="{
                                    backgroundImage: 'url(' + icon + ')'
                                }"
                            ></div>
                            <Button v-if="!icon" bType="create" :bLabel="$t('template.general.add_new')" :bCallback="openGalleryIcons" class="create-button"></Button>
                            <Button v-else bType="edit" :bLabel="$t('product.change_image')" :bCallback="openGalleryIcons" customClass="btn-neutro"></Button>
                        </div>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container align-bottom translate-hide">
                        <div class="header-limitation">
                            <div class="header-text">
                                <h2 class="label-form">{{ $t('template.general.site_limit.title') }}</h2>
                                <p class="description" v-html="$t('product.product_limit')"></p>
                                <p v-if="emptyAnswer.emptySite" class="error-form error-field" v-html="$t('product.empty_site')"></p>
                            </div>
                            <div class="switch">
                                <input class="switch-input" type="checkbox" v-model="siteLimit" />
                            </div>
                        </div>
                        <div class="user-answer">
                            <SiteLimit :definedMode="limitmode ? limitmode : ''" v-if="siteLimit" @setSiteLimit="setSiteLimit" :locations="locationsSave" :groups="groupsSave"></SiteLimit>
                        </div>
                    </div>
                    <!-- OLD note -->
                    <!-- <div class="right-container">
                    <h2 class="label-form">{{ $t('product.note') }}</h2>
                    <p class="description" v-html="$t('product.note_desc')"></p>
                    <p v-if="emptyAnswer.note" class="error-form error-field" v-html="$t('product.product_category.order_error')"></p>
                    <div class="user-answer">
                        <input type="text" v-model="productRender.note" class="input w85" autocomplete="off" name="name" />
                    </div>
                </div> -->

                    <!-- NEW note -->
                    <div class="right-container">
                        <TranslateInput :translation_keyname="'product.' + productRender.id + '.note'" gType="texteditor" v-model="productRender.note" :gState="emptyAnswer.note ? 'error' : ''" :gMessage="{ error: '' }" :gLabel="$t('product.note')" :gHelp="$t('product.note_desc')" :gOptions="{ actions: ['text', 'italic'] }"></TranslateInput>
                        <!-- <Input gType="texteditor" v-model="productRender.note" :gState="emptyAnswer.note ? 'error' : ''" :gMessage="{ error: '' }" :gLabel="$t('product.note')" :gHelp="$t('product.note_desc')" :gOptions="{ actions: ['text', 'italic'] }"></Input> -->
                    </div>
                </div>
                <div class="label-container assigned-states-product" :class="{ 'translate-hide': Object.keys(productRender.states).length == 0 }">
                    <div class="form-title">
                        <h1 class="form-title configure">
                            {{ $t('product.label_configured') }}
                        </h1>
                    </div>
                    <div class="label_configured_desc">
                        <span class="label_configured_desc" v-html="$t('product.label_configured_desc')"></span>
                    </div>

                    <div class="prod-assigned">
                        <div class="row-states">
                            <!-- <transition-group name="fade"> -->
                            <assignState :forceUpdate="key" :productRender="productRender" @update="updateAssign" v-if="productRender !== false" v-for="(state, index) in productRender.states" :states="{ ...state }" :key="index" :next_states="state.id ? nextStates[state.id] : nextStates[state.idtemp]" @updateState="updateState"></assignState>
                            <!-- </transition-group> -->
                            <div class="add-state translate-hide" @click="addState">
                                <span class="add-icon"></span> <span class="text">{{ $t('product.add_label') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="inline-delete" v-if="$route.params.id">
            <span class="text" @click="deleteProduct()">{{ $t('product.delete') }}</span>
        </div> -->
    </div>
</template>

<script>
import i18n from '@/i18n'
import assignState from '@/components/domain/product/assignProductState'
import SiteLimit from '@/components/ui/SiteLimit'

export default {
    name: 'ProductManagement',
    props: { id: undefined },
    components: {
        assignState,
        SiteLimit
    },
    data() {
        return {
            filenames: {},
            icon: false,
            statusProduct: undefined,
            product: {
                name: '',
                order: '',
                category: '',
                status: '1',
                states: {}
            },
            key: 0,
            limitmode: '',
            category_id: false,
            category: '',
            sendForm: true,
            emptyAnswer: {
                name: false,
                status: false,
                order: false,
                category: false,
                emptySite: false,
                time: false
            },
            form: false,
            newImage: false,
            possibleNextStates: {},
            moreOptions: false,
            locationsSave: '',
            groupsSave: '',
            siteLimit: false,
            imageBase: /data:image\/[^;]+;base64[^"]+/i
        }
    },
    computed: {
        galleryIcons() {
            var items = this.$store.getters['label/getGalleryImages']()
            return items
        },
        optionsCategories() {
            var categories = this.$store.getters['label/getCategoriesForm']()
            categories = Object.values(categories).sort(this.alphabetically(true))
            return categories
        },
        allStates() {
            return this.$store.getters['label/getStatesForm']()
        },
        productRender() {
            if (this.id) {
                if (this.$store.getters['label/getLabels'](this.id) != undefined) {
                    var product = this.$store.getters['label/getLabels'](this.id)

                    if (product.status == '-2') {
                        this.statusProduct = false
                    } else if (product.status == '1') {
                        this.statusProduct = true
                    }
                    if (product.category_id) {
                        this.category_id = product.category_id
                        var categories = this.$store.getters['label/getFilterCategories']()
                        if (categories) {
                            this.category = categories[product.category_id]
                            if (typeof categories[product.category_id] === 'undefined') {
                                this.category_id = ''
                            }
                        }
                    }

                    if (product.image != 'false') {
                        this.icon = product.image
                    }
                    if (Object.values(product.locations).length > 0 || Object.values(product.groups).length > 0) {
                        this.siteLimit = true
                        var locations = Object.values(product.locations)
                        var groups = Object.values(product.groups)
                        var locAux = []
                        var groupsAux = []
                        if (groups.length > 0) {
                            for (var index in groups) {
                                if (groups[index] != undefined) {
                                    groupsAux.push(groups[index].id)
                                }
                            }
                            this.groupsSave = groupsAux.toString()
                        }
                        if (locations.length > 0) {
                            for (var index in locations) {
                                if (locations[index] != undefined) {
                                    locAux.push(locations[index].id)
                                }
                            }
                            this.locationsSave = locAux.toString()
                        }
                    }
                    return product
                } else {
                    return false
                }
            } else {
                this.statusProduct = '1'
                return this.product
            }
        },

        nextStates: {
            get() {
                return this.possibleNextStates
            },
            set(states) {
                this.possibleNextStates = states
            }
        }
    },
    methods: {
        newTranslation(code) {
            this.$set(this.productRender.translations, code, { missing: 0, translated: 0 })
        },

        deleteTranslation(code) {
            this.$delete(this.productRender.translations, code)
        },

        updateAssign() {
            this.key = this.key += 1
        },
        alphabetically(ascending) {
            return function (a, b) {
                // equal items sort equally
                if (a.name === b.name) {
                    return 0
                }
                // nulls sort after anything else
                else if (a.name === null) {
                    return 1
                } else if (b.name === null) {
                    return -1
                }
                // otherwise, if we're ascending, lowest sorts first
                else if (ascending) {
                    return a.name < b.name ? -1 : 1
                }
                // if descending, highest sorts first
                else {
                    return a.name < b.name ? 1 : -1
                }
            }
        },
        setSiteLimit(data) {
            this.groupsSave = data.groups
            this.locationsSave = data.locations
            this.limitmode = data.mode
            if (data.mode) {
                this.sitelimit = true
            }
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        getCategory(id) {
            return this.$store.getters['label/getFilterCategories'](id)
        },
        selectIcon() {
            this.$refs.icon_product_selector.click()
        },
        addIcon() {
            this.$refs.add_icon_product_selector.click()
        },
        deleteIcon() {
            this.icon = false
        },
        previewFiles(e, name) {
            e.srcElement.nextSibling.src = '/img/file.svg'
            this.filenames = this.$refs[name].files[0].name
            // this.$overlay.loading();
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return
            this.createImage(files[0], name)
        },
        // CREATION THUMB
        createImage(file, name) {
            var image = new Image()
            var reader = new FileReader()
            var self = this

            reader.onload = (e) => {
                image.onload = () => {
                    self.icon = resizeImage(image)
                }
                image.src = e.target.result
            }
            reader.readAsDataURL(file)
            this.newImage = true
        },

        calcNextStates() {
            var states = { ...this.$store.getters['label/getStatesForm']() }
            var nextStates = {}

            for (let stateKey in this.productRender.states) {
                let aNextStates = []
                for (let nextKey in this.productRender.states) {
                    if (stateKey !== nextKey && this.productRender.states[stateKey].state !== this.productRender.states[nextKey].state) {
                        aNextStates.push(states[this.productRender.states[nextKey].state])
                    }
                }
                nextStates[stateKey] = aNextStates
            }
            this.nextStates = { ...nextStates }
        },

        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },

        popupStatus() {
            var selfThis = this
            if (selfThis.statusProduct == '1' || selfThis.statusProduct == true) {
                selfThis.$popup.confirm({
                    message: selfThis.$t('product.popup_desc'),
                    textSave: selfThis.$t('product.popup_confirm'),
                    callSave: function () {
                        selfThis.statusProduct = false
                    },
                    callCancel: function () {
                        selfThis.statusProduct = true
                    }
                })
            } else {
                selfThis.statusProduct = true
            }
        },
        image(image) {
            this.icon = image
        },
        openGalleryIcons() {
            const self = this

            const category = this.$store.getters['label/getCategoriesForm'](this.category_id)
            const productName = this.productRender.name.trim()
            const categoryName = typeof category.name !== 'undefined' ? category.name.trim() : null

            if (productName != '' && categoryName != '') {
                const params = {
                    product_name: productName,
                    category_name: categoryName
                }
                this.$store.dispatch('label/galleryLoadBestImages', params)
            }

            self.$popup.galeryIcons({
                title: this.$t('product.gallery_icons.title'),
                image: self.icon,
                textSave: this.$t('button.save'),
                data: this.galleryIcons,
                textCancel: this.$t('button.cancel'),
                productName: productName,
                callSave: function () {
                    self.image(this.image)
                    self.$popup.close()
                },
                callCancel: function () {
                    self.$popup.close()
                }
            })
        },
        validateStateActive(id) {
            var valid = this.$store.getters['label/getStatesForm'](id)
            return valid
        },

        load() {
            var self = this
            var items = this.$store.getters['label/getGalleryImages']()
            if (Object.keys(items).length == 0) {
                this.$store.dispatch('label/loadGalleryImages', {})
            }
            if (this.$route.name == 'Product') {
                if (this.id != false) {
                    this.$router.push({
                        name: 'EditProduct',
                        params: { id: this.id }
                    })
                } else {
                    this.$router.push({ name: 'AddProduct' })
                }
            }

            this.calcNextStates()
        },

        goBack() {
            this.$overlay.show()
            this.$emit('back')
        },

        validateProduct() {
            this.sendForm = true

            let prod = { ...this.productRender }

            // set all values of errors to false
            this.emptyAnswer = _.mapValues(this.emptyAnswer, () => false)

            if (prod.name === '') {
                this.sendForm = false
                this.emptyAnswer.name = true
            }

            if (this.icon) {
                if (this.icon.match(this.imageBase)) {
                    prod.file = this.icon
                } else {
                    prod.image = this.icon
                }
            } else {
                prod.image = this.icon
            }

            // Clean product note
            if (prod.note == undefined || !prod.note) {
                prod.note = ''
            }
            if (prod.note) {
                prod.note = prod.note.replace('<p>', '').replace('</p>', '')
            }

            var statesUsed = []
            for (var states in prod.states) {
                var idx = prod.states[states]
                if (idx.status == 1) {
                    if (this.validateStateActive(idx.state) == undefined) {
                        idx.error1 = true
                        this.sendForm = false
                    }

                    if (statesUsed.includes(idx.state)) {
                        idx.error1 = true
                        this.sendForm = false
                    } else {
                        statesUsed.push(idx.state)
                    }

                    if (idx) {
                        if (idx.format !== undefined) {
                            if (idx.time < 1 && idx.format == 'time' && idx.preptime < 1 && idx.status == 1) {
                                this.sendForm = false
                                idx.error = true
                            }
                        } else if ((idx.time < 1 && idx.format == 'time' && idx.preptime < 1 && idx.status == 1) || (idx.preptime === undefined && idx.time === undefined && idx.format === undefined && idx.status == 1)) {
                            this.sendForm = false
                            idx.error = true
                        }
                    }
                } else {
                    idx.status = -2
                }
            }

            prod.status = this.statusProduct

            if (prod.status == true) {
                prod.status = '1'
            } else if (prod.status == false || prod.status == undefined) {
                prod.status = '-2'
            }

            if (this.siteLimit) {
                if (this.locationsSave != '') {
                    prod.locations = this.locationsSave
                } else if (this.groupsSave != '') {
                    prod.groups = this.groupsSave
                    if (prod.locations != '') {
                        prod.locations = []
                    }
                } else if (this.locationsSave == '' && this.groupsSave == '') {
                    this.emptyAnswer.emptySite = true
                    this.sendForm = false
                    prod.groups = this.groupsSave
                    prod.locations = this.locationsSave
                    this.locationsSave = this.locationsSave
                    this.groupsSave = this.groupsSave
                }
            } else if (!this.siteLimit) {
                this.groupsSave = ''
                this.locationsSave = ''
                prod.locations = []
                prod.groups = []
            }

            if (this.category_id == '') {
                this.sendForm = false
                this.emptyAnswer.category = true
            } else {
                prod.category_id = this.category_id
            }

            return prod
        },

        saveProduct() {
            const self = this
            this.$overlay.loading()
            let prod = this.validateProduct()

            if (this.sendForm) {
                if (this.$route.params.id) {
                    this.$store.dispatch('label/editProduct', { ...prod }).then(function (response) {
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$t('snackbar.edit_success') + ' <b>' + self.productRender.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                } else {
                    this.$store.dispatch('label/addProduct', { ...prod }).then(function (response) {
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$t('snackbar.add_success') + ' <b>' + self.productRender.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                        const amplitudeProps = {}

                        const employee = self.$store.getters['login/getLocalEmployee']
                        if(employee) {
                            amplitudeProps.employee = {fullname: `${employee.name} ${employee.surname}`, account_name: employee.account_name, employee_email: employee.email} 
                        }
                        
                        self.$amplitude.track('Admin product creation', amplitudeProps)
                    })
                }
            } else {
                self.$overlay.hide()
                scrollError()
            }
        },

        deleteProduct() {
            var self = this

            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.productRender.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store.dispatch('label/removeProduct', self.productRender.id).then(() => {
                self.$snackbar.open(snackbarData)
                self.$emit('back')
            })
        },

        showConfirmDeleteProductModal() {
            var self = this
            const modalMessage = self.$t('product.popup_delete') + self.productRender.name + "'?"

            self.$popup.delete({ message: modalMessage, callSave: self.deleteProduct })
        },

        clone() {
            const self = this
            this.$popup.confirm({
                message: this.$t('product.popup_clone'),
                textSave: this.$t('product.popup_button_clone'),
                callSave: async () => {
                    self.$overlay.loading()
                    let prod = self.validateProduct()

                    if (self.sendForm) {
                        await self.$store.dispatch('label/editProduct', { ...prod })
                        await self.$store.dispatch('label/cloneProduct', { id: self.id })
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$t('snackbar.clone_success') + ' <b>' + self.productRender.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    } else {
                        self.$overlay.hide()
                        scrollError()
                    }
                },
                callCancel: () => self.$popup.close()
            })
        },

        updateState(state) {
            if (state.id) {
                this.productRender.states[state.id] = { ...state }
            } else {
                this.productRender.states[state.idtemp] = { ...state }
            }
            this.calcNextStates()
        },

        addState() {
            var idtemp = 's' + moment().valueOf()
            var states = Object.values(this.$store.getters['label/getStatesForm']())
            var state = false
            var label = false
            var self = this

            states.forEach((s) => {
                var bFound = false
                if (!state) {
                    for (var k in self.productRender.states) {
                        if (self.productRender.states[k].state == s.id) {
                            bFound = true
                        }
                    }
                    if (!bFound) {
                        state = s.id
                    }
                }
            })
            if (!state) state = states[0].id

            var aux = Object.values(this.$store.getters['label/getFilterProductLabelConfig']())

            aux.sort(this.alphabetically(true))
            label = aux[0].id

            if (Array.isArray(this.productRender.states)) {
                this.productRender.states = {}
            }

            this.productRender.states[idtemp] = {
                new: true,
                idtemp: idtemp,
                state: state,
                next_state: false,
                msg: '',
                status: 1,
                config_id: label
            }

            this.calcNextStates()
            this.$forceUpdate()
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {
        icon: {
            deep: true,

            handler() {
                this.$overlay.hide()
            }
        }
    },
    updated() {}
}
</script>

<style lang="scss">
#content.product-management {
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .product-assign-state {
        transition: all 0.3s;
    }
    .fade-leave-active {
        position: absolute;
    }

    .form-container {
        .form {
            width: 96%;

            @media (min-width: 1100px) {
                width: 92%;
            }

            @media (min-width: 1240px) {
                width: 1100px;
            }

            @media (min-width: 1600px) {
                width: 1200px;
            }

            @media (min-width: 2000px) {
                width: 1400px;
            }
        }

        .header-limitation {
            @include display-flex();
        }

        .image {
            @include border-radius(4px);
            border: 1px solid #dddddd;
            width: 60px;
            height: 60px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            margin-right: 10px;
        }
        .form-title {
            margin-bottom: 0;
        }
    }
    .left-container {
        padding-left: 0;
    }
    .label_configured_desc {
        font-family: $text;
        margin-bottom: 20px;
    }

    .product-icon {
        @include background($color: $color-neutral-200, $image: img('product_neutro.svg'), $size: cover);
        @include border-radius(50%);
        width: 60px;
        height: 60px;
    }
    .icon-changes {
        @include display-flex();
        @include align-items();
    }
    .user-answer {
        height: auto !important;
    }

    .prod-assigned-columns {
        @include display-flex();
        @include justify-content(inherit);
        padding-bottom: 30px;
        width: calc(100% - 30px);

        .column {
            @include font-size(md);
            font-family: $text-bold;
            width: 20%;
            padding-left: 15px;

            &.format {
                width: 10%;
            }

            &.pad {
                padding-left: 20px;
            }
        }
    }

    .add-state {
        @include display-flex();
        @include border-radius(4px);
        @include justify-content();
        background-color: $color-primary-500;
        width: fit-content;
        cursor: pointer;
        width: calc(100% - 33px);
        padding: 18px;
        margin-top: 10px;
        .add-icon {
            @include background($image: img('add_ffffff.svg'), $size: 15px, $position: center center);
            width: 20px;
            height: 20px;
            margin-top: 4px;
        }
        .text {
            @include font-size(ml);
            font-family: $text-medium;
            padding-left: 6px;
            color: $color-white;
        }
        &:hover {
            background-color: $color-primary-800;
        }
    }

    .right-container {
        .create-button {
            margin: 0 !important;
            background-color: $color-primary-500 !important;
        }
    }
}
</style>
